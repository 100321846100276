<template>
	<div class="login-register">
		<div class="bg-box">
			<div class="sub-box">
				<div class="logo-title">{{ pcConfig.title }}</div>
				<div class="logo-label">一个人，靠AI搞定所有工作，效率直接拉满。</div>
				<!-- <div class="logo-label">功能齐全，智能辅助，每天都干劲十足。</div>
				<div class="logo-label">在这里工作，成就感满满，充满动力。</div>
				<div class="logo-label">创业路上，AI是你最强的助手。</div> -->
			</div>
		</div>
		<div class="flex flex-center login-box">
			<div class="login-panel">
				<!-- v-show="qrImage" -->
				<div class="tab-box">
					<div class="tab-title">
						<div :class="['item', { active: activeTab === 'qrcode' }]" @click="tabClick('qrcode')">
							扫码登录
						</div>
						<div :class="['item', { active: activeTab === 'login' }]" @click="tabClick('login')">
							验证码登录
						</div>
					</div>
					<div class="tab-content">
						<div class="item" v-if="activeTab === 'qrcode'">
							<div class="qrcode-container">
								<el-image :src="qrImage">
									<div
										slot="placeholder"
										class="image-slot"
										style="
											width: 130px;
											height: 130px;
											background: #f1f5f9;
											font-size: 12px;
											color: var(--text-black-light);
										"
									>
										加载中<span class="dot">...</span>
									</div>
								</el-image>
								<div v-if="!scanSuccess" class="scan-tip" style="color: var(--text-black-light)">
									请使用微信扫码登录
								</div>
								<div v-else class="scan-tip">扫码成功，请在手机上确认登录</div>
							</div>
						</div>
						<div class="item" v-if="activeTab === 'login'">
							<el-form ref="loginForm" :model="loginForm" label-width="80px">
								<el-input size="medium" placeholder="请输入手机号码" v-model="loginForm.phone">
									<template slot="prefix">
										<div class="login-icon-box">
											<img src="../../static/icon/login-user-icon.png" class="icon" />
										</div>
									</template>
								</el-input>
								<div class="flex mt20">
									<el-input
										size="medium"
										placeholder="输入验证码【发送方：米蜗城市】"
										v-model="loginForm.code"
									>
										<template slot="prefix">
											<div class="login-icon-box">
												<img src="../../static/icon/login-code-icon.png" class="icon" />
											</div>
										</template>
										<template slot="suffix">
											<div class="login-yzm-box">
												<a v-show="countdown < 1" class="send-code" @click="sendCode"
													>发送验证码</a
												>
												<a v-show="countdown > 0" class="send-code">{{ countdown }}秒后重试</a>
											</div>
										</template>
									</el-input>
								</div>
								<div class="mt20 flex">
									<div class="login-btn" @click="login">登录</div>
								</div>
							</el-form>
						</div>
					</div>
				</div>
				<!-- <el-tabs v-model="activeTab" @tab-click="tabClick(activeTab)">
          <el-tab-pane label="扫码登录" name="qrcode">
            <div class="qrcode-container">
              <el-image :src="qrImage">
                <div slot="placeholder" class="image-slot" style="
										width: 130px;
										height: 130px;
										background: #f1f5f9;
										font-size: 12px;
										color: var(--text-black-light);
									">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
              <div v-if="!scanSuccess" class="scan-tip" style="color: var(--text-black-light)">
                请使用微信扫码登录
              </div>
              <div v-else class="scan-tip">扫码成功，请在手机上确认登录</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="验证码登录" name="login">
            <el-form ref="loginForm" :model="loginForm" label-width="80px">
              <el-input prefix-icon="el-icon-phone-outline" placeholder="请输入手机号码" v-model="loginForm.phone"></el-input>
              <div class="flex mt20">
                <el-input placeholder="输入验证码【发送方：米蜗城市】" prefix-icon="el-icon-message" class="mr6"
                  v-model="loginForm.code"></el-input>
                <a v-show="countdown < 1" class="send-code" @click="sendCode">发送验证码</a>
                <a v-show="countdown > 0" class="send-code">{{ countdown }}秒后重试</a>
              </div>
              <div class="mt20 flex">
                <el-button type="primary" class="login-btn" @click="login">登录</el-button>
              </div>
            </el-form>
          </el-tab-pane>
        </el-tabs> -->
				<div class="copy-right">
					<span class="title">一城一智(广东)科技有限公司版权所有 </span
					><a href="https://beian.miit.gov.cn/" target="_blank"> 粤ICP备2020076772号-19</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	getLogingQR,
	loginSms,
	qrLoginCheck,
	sendSmsCode,
	aiMouseLoginApi,
	getPcShowConfigApi,
	getXYPcUrlGetMerchantGuid,
} from '@/apis/user';
import { setToken } from '@/utils/auth';
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
	name: 'login',

	components: {
		Carousel3d,
		Slide,
	},

	mounted() {
		let params = new URLSearchParams(window.location.href.split('?')[1]);
		if (params.get('inviteCode')) {
			this.isInvite = true;
			this.inviteCode = params.get('inviteCode');
			this.inviteType = params.get('type');
		}
		//ai鼠标登录
		if (params.get('h')) {
			let aiLoginCode = params.get('h');
			this.aiMouseLogin(aiLoginCode);
		}
		this.getMerchantGuid();
	},

	destroyed() {
		this.qrInterval && clearInterval(this.qrInterval);
	},

	data() {
		return {
			activeTab: 'qrcode',
			// activeTab: 'login',
			loginForm: {
				phone: '',
				code: '',
			},
			countdown: 0,
			scanSuccess: false,
			slides: 4,
			qrImage: '',
			qrCode: '',
			qrInterval: null,
			isInvite: false,
			inviteCode: '',
			inviteType: '',
			pcConfig: {
				title: '',
			},
			merchantGuid: '',
		};
	},

	methods: {
		async getMerchantGuid() {
			let url = window.location.origin;
			let res = await getXYPcUrlGetMerchantGuid({
				pcUrl: url,
			});
			this.merchantGuid = res.data.merchantGuid;
			this.$store.commit('setMerchantGuid', res.data.merchantGuid);
			this.getPcShowConfig();
			this.generateQrcode();
		},
		async tabClick(tab) {
			this.activeTab = tab;
			if (tab == 'qrcode') {
				this.startScan();
			} else {
				clearInterval(this.qrInterval);
				this.qrInterval = null;
			}
		},
		async aiMouseLogin(code) {
			let res = await aiMouseLoginApi({ h: code });
			if (res.code === 0) {
				clearInterval(this.qrInterval);
				this.qrInterval = null;
				let token = res.data.token;
				this.$message({
					message: 'AI鼠标已识别正在登录....',
					type: 'success',
				});
				setToken(token);
				setTimeout(() => {
					this.$router.push('/app/chat');
				}, 1000);
			} else {
				this.$message({
					message: res.msg,
					type: 'error',
				});
			}
		},
		async getPcShowConfig() {
			let { data } = await getPcShowConfigApi({ merchantGuid: this.merchantGuid });
			this.pcConfig = data;
			document.title = data.title ? data.title : 'AI龙龙，一个人的工作站';
		},

		async login() {
			let { phone, code } = this.loginForm;
			let logReq = null;
			if (this.isInvite) {
				logReq = {
					phone: phone,
					smsCode: code,
					merchantGuid: this.merchantGuid,
					invitationText: this.inviteCode,
					invitationType: this.inviteType,
				};
			} else {
				logReq = {
					phone: phone,
					smsCode: code,
					merchantGuid: this.merchantGuid,
				};
			}
			let res = await loginSms(logReq);
			if (res.code === 0) {
				let token = res.data;
				setToken(token);
				clearInterval(this.qrInterval);
				setTimeout(() => {
					this.$router.push('/app/chat');
				}, 500);
			} else {
				this.$message({
					message: res.msg,
					type: 'error',
				});
			}
		},

		async generateQrcode() {
			let url = window.location.origin;
			let logReq = null;
			if (this.isInvite) {
				logReq = {
					invitationType: this.inviteType,
					invitationText: this.inviteCode,
					merchantGuid: this.merchantGuid,
					pcUrl: url,
				};
			} else {
				logReq = {
					merchantGuid: this.merchantGuid,
					pcUrl: url,
				};
			}
			let { codeImg, loginCode } = await getLogingQR(logReq);
			this.qrImage = codeImg;
			this.qrCode = loginCode;
			this.startScan();
		},

		startScan() {
			this.qrInterval = setInterval(async () => {
				let res = await qrLoginCheck(this.qrCode);
				if (res.data.isLogin) {
					let { isLogin, token } = res.data;
					if (token) {
						// console.log(data)
						clearInterval(this.qrInterval);
						setToken(token);
						this.$router.push('/app/chat');
					}
				}
			}, 3000);
		},

		async sendCode() {
			let { phone } = this.loginForm;

			if (phone) {
				await sendSmsCode({
					imageCode: 'Jwjoz',
					phone: phone,
					from: 'xiaoyi',
				});

				this.countdown = 60;
				this.$message({ message: '验证码已发送', type: 'success' });

				let intervalId = setInterval(() => {
					this.countdown--;
					if (this.countdown <= 0) {
						clearInterval(intervalId);
					}
				}, 1000);
			}
		},
	},
};
</script>

<style lang="scss">
.login-yzm-box {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-icon-box {
	width: 36px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.code-icon {
		width: 20px;
		height: 20px;
	}
}

.logo-title {
	background: linear-gradient(110deg, #d072ff 0%, #06f2ff 47%, #1d75ff 100%);
	background-clip: text;
	color: transparent;
	font-weight: 600;
	font-size: 52px;
	letter-spacing: 2px;
}

.logo-label {
	font-size: 14px;
	color: #666666;
	margin-top: 10px;
}

.login-register {
	width: 100%;
	height: 100%;
	// background: #eaeff8;
	background: linear-gradient(270deg, #f3fbff 0%, rgba(243, 251, 255, 0) 100%);
	box-sizing: border-box;
	overflow: hidden;
	display: flex;

	.bg-box {
		width: 64%;
		background-image: url('https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/image/3868f7b9c1044b00b01a9a56b5fb41a0.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center;
		display: flex;
		justify-content: end;

		.sub-box {
			padding-right: 140px;
			margin-top: 26vh;
		}
	}

	.login-box {
		width: 46%;
		position: relative;
		.copy-right {
			position: absolute;
			bottom: 10px;
			left: 50%;
			transform: translateX(-50%);
			.title {
				font-size: 12px;
			}
			a {
				color: #6126f5;
				text-decoration: none;
				font-size: 12px;
			}
		}
	}

	.login-panel {
		width: 480px;

		.tab-box {
			width: 380px;

			.tab-title {
				display: flex;
				justify-content: center;
				margin-bottom: 35px;

				.item {
					cursor: pointer;
					padding: 10px 20px;
					color: #444444;
					font-size: 20px;
					font-weight: bold;
					position: relative;

					&.active {
						color: #6126f5;

						&::after {
							content: '';
							width: 40px;
							height: 3px;
							background-color: #6126f5;
							position: absolute;
							bottom: 0px;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}
		}

		// .el-tabs__header {
		//   margin-bottom: 30px;
		// }

		.el-input__icon {
			line-height: 32px;
			font-size: 18px;
			margin-left: 4px;
		}

		.el-input {
			width: 380px;
			font-size: 12px;

			.el-input__inner {
				background-color: transparent;
				// height: 40px !important;
				border-radius: 4px;
				padding-left: 40px;
			}
		}

		.el-tabs__nav > div {
			color: #300c8a;
			font-size: 20px;
			font-weight: 700;
			cursor: default;
		}

		.send-code {
			font-size: 12px;
			width: 90px;
			text-align: right;
			line-height: 50px;
			position: absolute;
			right: 20px;
			color: #155bec;
			font-weight: bold;
		}
	}

	.login-btn {
		width: 100%;
		cursor: pointer;
		border-radius: 4px;
		height: 40px;
		background: linear-gradient(308.73deg, #2714f5 -11.66%, #a098ec 102.03%);
		font-size: 14px;
		color: #fff;
		letter-spacing: 3px;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.qrcode-container {
	text-align: center;
	margin-top: 20px;

	img {
		width: 130px;
		height: 130px;
	}
}

.scan-tip {
	margin-top: 10px;
}

@media screen and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
	.login-register {
		flex-direction: column;
		background-image: url('https://xiaoyi-1317629730.cos.ap-guangzhou.myqcloud.com/image/3868f7b9c1044b00b01a9a56b5fb41a0.png');
		background-repeat: no-repeat;
		background-position: center top;

		.bg-box {
			width: 100%;
			background-image: none;

			.sub-box {
				margin-top: 200px;
				padding: 0px 20px;

				.logo-title {
					font-size: 24px;
				}
			}
		}
	}

	.login-register {
		.login-box {
			width: 100%;
			margin-top: 30px;

			.tab-box {
				margin: 0 auto;
				padding: 30px 0;
				border-radius: 6px;
				background-color: #fff;
			}
			.copy-right {
				display: none;
			}
		}

		// .login-panel {
		//   width: auto;
		//   margin: auto;
		// }
	}
}
</style>
